<template>
  <div class="text-left">
    <BaseHeader :title="'Discounts'"></BaseHeader>

    <!-- <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading> -->

    <section>
      <div class=" mt-2">

        <div class="card orderlisting--card py-2">
        <section class=" order-listing-header-main p-3  ">  
          <div class=" d-flex align-items-center justify-content-between header-elements-inline pb-0">
            <!-- <h2 class="card-title text--dark--custom font-weight-black text-capitalize">
               {{ $route.params.status }} 
            </h2> -->
            <base-select-site
              class="p-2"
              @siteSelected="_getDiscounts"
            ></base-select-site>
            <div class="header-elements">
              <button 
                color="primary"
                class="my-auto btn btn-add ml-2"
                size="small"
                @click.stop="crudAction"
                >New</button
              >
            </div>
          </div>
        
        </section>

        <div class=" mx-0  order-table-custom ">
        
          <div  class=" order-listing-header    row  p-3 mx-sm-0 mx-1 ">  
            <div class="col-1 text-left text--dark--custom "> ID </div>
            <div class="col-2 text-left text--dark--custom ">Code</div>
            <div class="col-1 text-left text--dark--custom">Percentage</div>
            <div class="col-2 text-left text--dark--custom"> On Orders Above</div>
            <div class="col-1 text-left text--dark--custom">Recursive</div>
            <div class="col-1 text-left text--dark--custom">Private</div>
            <div class="col-1 text-left text--dark--custom"> Order Based</div>
            <div class="col-2 text-left text--dark--custom">Date Created</div>
            <div class="col-1 text-left text--dark--custom">Action </div>
        </div>
      

        <div v-if="discounts.length > 0" class="">
        <div v-for="(item, index) in discounts" :key="index + 'ords'" class="order-listing-body row px-3 py-3 mx-sm-0 mx-1 ">  
            <div class="col-1 d-flex align-items-start justify-content-start text-left">	
              <span class="px-2"
                >{{ item.id || "" }}</span>
              
            </div>
            <div class="col-2 text-left">
              <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ item.code || "" }}</div>
            </div>
            <div class="col-1 text-left">{{ item.percentage || "" }}%</div>
            <div class="col-2 text-left">  ${{ item.on_orders_above || "" }} </div>
            <div class="col-1 text-left">
              <v-chip v-if="item.recursive != 0" color="primary" outlined small
                >Recursive</v-chip
              >
            </div>
            <div class="col-1 text-left">
              <v-chip v-if="item.private != 0" color="primary" outlined small
                >Private</v-chip>

              
            
            </div>
            <div class="col-1 text-left"> {{ "" }}</div>
            <div class="col-2 text-left">
              <span class="font-weight-bold" 
              >{{ item.create_stamp || "" }}</span
            >
            </div>
            <div class="col-1 text-left  ">
              <v-btn
              elevation="0"
              @click="
                editMode(item);
                $store.state.dialog = true;
              "
              fab
              x-small
            >
              <v-icon small color="error">mdi-delete</v-icon>
            </v-btn>
            </div>
        </div>
        </div>

        </div>
        <div class="p-3 d-flex align-items-center justify-content-center w-100 "  v-if="discounts.length < 1">
          
              <strong class="order_empty  text-center" >No discounts.</strong>
          
        </div>
      </div>
      </div>

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="discountsForm.busy">
          <v-card-title class="text-h5">
            {{ edit ? `Update ${discountsForm.level || ""}` : "New Discount" }}
          </v-card-title>
          <v-card-text>
            <!--  form -->
            <form
              class="flex-fill text-left"
              ref="discountsForm"
              @submit.prevent="createWriterLevel"
            >
              <div class>
                <div class="mb-3">
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Discount Code e.g. WELCOME10"
                      name="code"
                      type="text"
                      v-model="discountsForm.code"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="discountsForm"
                            class="v-messages theme--light error--text"
                            field="code"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Percentage Discount"
                      name="percentage"
                      type="text"
                      v-model="discountsForm.percentage"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="discountsForm"
                            class="v-messages theme--light error--text"
                            field="percentage"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="On orders above"
                      name="on_orders_above"
                      v-model="discountsForm.on_orders_above"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="discountsForm"
                            class="v-messages theme--light error--text"
                            field="on_orders_above"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <div class="alert alert-info mb-0 py-1 px-2">
                      <p class="font-weight-bold text-uppercase">Note:</p>
                      <ul>
                        <li>
                          A private discount code cannot be seen on the order
                          form while placing an order.
                        </li>
                        <li>
                          A recursive discount code can be reused many times
                        </li>
                      </ul>
                    </div>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-switch
                      v-model="discountsForm.private"
                      label="Private"
                      color="primary"
                      :value="discountsForm.private"
                      class="mt-0"
                      hide-details
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-switch
                      v-model="discountsForm.recursive"
                      label="Is Recursive"
                      color="primary"
                      :value="discountsForm.recursive"
                      class="mt-0"
                      hide-details
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" md="4" v-show="edit">
                    <v-switch
                      v-model="discountsForm.status"
                      label="Is Active"
                      color="primary"
                      :value="discountsForm.status"
                      class="mt-0"
                      hide-details
                    ></v-switch>
                  </v-col>
                </v-row>

                <v-btn-toggle class="mt-3">
                  <v-btn
                    :disabled="discountsForm.busy"
                    type="submit"
                    color="primary"
                    class="white--text"
                    >{{ edit ? "Update" : "Save" }}</v-btn
                  >
                  <v-btn
                    type="button"
                    @click="$store.state.dialog = !$store.state.dialog"
                    >Close</v-btn
                  >
                </v-btn-toggle>
              </div>
            </form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Discounts",
  computed: {
    ...mapState("payment", [
      "discountsForm",
      "loading",
      "discounts",
      "discountHeaders",
    ]),
  },
  data() {
    return {
      countries: [],
      edit: false,
    };
  },

  methods: {
    ...mapActions("payment", ["_getDiscounts"]),
    // ...mapActions("clients", ["getClients"]),
    createWriterLevel() {
      this.discountsForm.default = this.discountsForm.default ? true : false;
      this.discountsForm.recursive = this.discountsForm.recursive ? 1 : 0;
      this.discountsForm.order_based = this.discountsForm.order_based ? 1 : 0;
      this.discountsForm.private = this.discountsForm.private ? 1 : 0;
      this.discountsForm.site_id = this.$store.state.site_id;
      console.log("discountsForm", this.discountsForm);
      this.edit
        ? this.discountsForm
            .put(`${this.$baseUrl}/Discounts/Discount/`)
            .then((res) => {
              this.$store.state.dialog = false;
             

              this.$swal({
                icon: 'success',
                title: 'Success',
                text: `Discount updated successfully.`,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
              this._getDiscounts();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              

              this.$swal({
              icon: 'error',
              title: 'Error',
              text: err.response.data
                  ? err.response.data.Message
                  : `Error while updating discount`,
                style: "danger",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
            })
        : this.discountsForm
            .post(`${this.$baseUrl}/Discounts/Discount/`)
            .then((res) => {
              this.$store.state.dialog = false;
             

              this.$swal({
                icon: 'success',
                title: 'Success',
                text: `Discount created successfully.`,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
              this._getDiscounts();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              

              this.$swal({
              icon: 'error',
              title: 'Error',
              text: err.response.data
                  ? err.response.data.Message
                  : `Error while creating discount`,
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
            });
    },

    editMode(style) {
      this.edit = true;
      this.discountsForm.reset();
      this.$store.state.dialog = true;
      Object.assign(this.discountsForm, { ...style });
      this.discountsForm.default =
        this.discountsForm.default !== "0" ? true : false;
      this.discountsForm.active =
        this.discountsForm.active !== "0" ? true : false;
    },

    crudAction() {
      if (this.$store.state.site_id) {
        this.edit = false;
        this.discountsForm.reset();
        this.$store.state.dialog = true;
      } else {
       

        this.$swal({
              icon: 'error',
              title: 'Error',
              text:"Please select a site to proceed",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
      }
    },
  },
  async mounted() {
    // this.$store.state.site_id = this.$route.params.siteid

    if (this.$store.state.site_id) {
      await this._getDiscounts();
    }
  },
};
</script>
